import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1080p_Series/Network/SSL_Certificate/SuccessBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Network Menu // SSL Certificate",
  "path": "/Web_User_Interface/1080p_Series/Network/SSL_Certificate/",
  "dateChanged": "2017-12-11",
  "author": "Mike Polinowski",
  "excerpt": "Change your cameras IP address, gateway and DNS server - all these settings are usually set up by the Installation Wizard and don´t need to be changed. The camera´s http port might have to be changed to access your cameras webUI over the Internet. The RTSP port is the camera´s RTSP Streaming Port.",
  "image": "./WebUI_1080p_SearchThumb_Network_IP_Configuration.png",
  "social": "/images/Search/WebUI_1080p_SearchThumb_Network_IP_Configuration.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1080p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Network Menu // SSL Certificate' dateChanged='2017-12-11' author='Mike Polinowski' tag='INSTAR IP Camera' description='Change your cameras IP address, gateway and DNS server - all these settings are usually set up by the Installation Wizard and don´t need to be changed. The camera´s http port might have to be changed to access your cameras webUI over the Internet. The RTSP port is the camera´s RTSP Streaming Port.' image='/images/Search/WebUI_1080p_SearchThumb_Network_IP_Configuration.png' twitter='/images/Search/WebUI_1080p_SearchThumb_Network_IP_Configuration.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1080p_Serie/Netzwerk/SSL_Zertifikat/' locationFR='/fr/Web_User_Interface/1080p_Series/Network/SSL_Certificate/' crumbLabel="SSL" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1080p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1080p-web-user-interface",
        "aria-label": "1080p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1080p Web User Interface`}</h1>
    <h2 {...{
      "id": "network-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#network-menu",
        "aria-label": "network menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Network Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1d13f9de7266c30e5efd762febdefea7/8dd93/1080p_Settings_Network_SSL_Certificate.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.43478260869564%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADDElEQVQoz1WSbWhVdRzHjy8SXOSLzYKxNAfV1mpLbZkwenGfYpbeNRNHTwN3rYmK6Tz/h/PkPf97RuGCa7OnKUxi994pMRMKBiVz+kZaEUS9krlUaOxenXs4nXN2T/d//z853gr68Hv74fuF70+iGBFCKKWKouoqRSx98LOr+09d2Zee7ElPJtKXe9KXE+mJA19c6/tw2FCpcTyZSCRant+8acsLEkboGCIYY8ZYR3znrt175mz+R8GZmXNm8u50fmUm796Ysxd8+PbiN6T7XdS9d+9rb+zc2tbW0CQhhI/KBBPSb1nhSCwSaQfgJd8r/70C/8JLPgBMfXf+9OH3Pt6vaR3d6vZQ56ZWiRISJBPCWCq+49UjB3sAwKlQcv6ROQeA8XMXDm9/J937/mDvB69HEpubt0kqJUdlIiOSSlk72mO9b3fwMhSLRc657Sw67rLrLi/cuwMA2S+Hxo50/m7Ffk2/Oca0vldeDGSEKcaV2tFwOAoAvu9zzn2/6Hmu57m2vQQAly5mD3W27ml99qP4xonexyf2NQWyjCkhxLKsSCQSjcaEEI7juK7r+0XOS5yXXC/oP3rh67oNTzU++dxjj9Y11FaTl6oliklfMFZFjkajsVKp5HkeACwt3Z2fzxcKs7duTQPA56c+ra1Z19jQWFNT/XDVmsgTaySMsIwDub+/PxQKhcMhLsBd8YUQtr1g2/cWF+/k524DwInBk9W16zY+Xf/I2qrVD61qq10lUYxx8COEMRaPx7u6usBfEn8VhChXEGUxv+ytcDg7lom+1f7yrnDzlpZnmpvW19dLGiXKA1RVxRhnsxmYnYKbk1Dm/+3825/ez7Mw/v0lTVNSlmWaZjKZZGbyfzIheHQ0BwBCgBACAKYL/uR1d/HHX+CnqR/GxxHGqVTKNE3GmGmakq4QVVWCUxRV004MDGQzmWxmJJfLjeZyw19lhoZHzg6dGTl9ZvDkJ8ZxoxJbQZJlJOPgTxCmmqZhSvuOybKMEEIYY01VDE3VdF3RdcMwGGPJZNIwDF3XKaX3AS0O9R+nCPy6AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1d13f9de7266c30e5efd762febdefea7/e4706/1080p_Settings_Network_SSL_Certificate.avif 230w", "/en/static/1d13f9de7266c30e5efd762febdefea7/d1af7/1080p_Settings_Network_SSL_Certificate.avif 460w", "/en/static/1d13f9de7266c30e5efd762febdefea7/7f308/1080p_Settings_Network_SSL_Certificate.avif 920w", "/en/static/1d13f9de7266c30e5efd762febdefea7/19805/1080p_Settings_Network_SSL_Certificate.avif 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1d13f9de7266c30e5efd762febdefea7/a0b58/1080p_Settings_Network_SSL_Certificate.webp 230w", "/en/static/1d13f9de7266c30e5efd762febdefea7/bc10c/1080p_Settings_Network_SSL_Certificate.webp 460w", "/en/static/1d13f9de7266c30e5efd762febdefea7/966d8/1080p_Settings_Network_SSL_Certificate.webp 920w", "/en/static/1d13f9de7266c30e5efd762febdefea7/61eb3/1080p_Settings_Network_SSL_Certificate.webp 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1d13f9de7266c30e5efd762febdefea7/81c8e/1080p_Settings_Network_SSL_Certificate.png 230w", "/en/static/1d13f9de7266c30e5efd762febdefea7/08a84/1080p_Settings_Network_SSL_Certificate.png 460w", "/en/static/1d13f9de7266c30e5efd762febdefea7/c0255/1080p_Settings_Network_SSL_Certificate.png 920w", "/en/static/1d13f9de7266c30e5efd762febdefea7/8dd93/1080p_Settings_Network_SSL_Certificate.png 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1d13f9de7266c30e5efd762febdefea7/c0255/1080p_Settings_Network_SSL_Certificate.png",
              "alt": "Web User Interface - 1080p Series - Network SSL Certificate",
              "title": "Web User Interface - 1080p Series - Network SSL Certificate",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "format",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#format",
        "aria-label": "format permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Format`}</h2>
    <p>{`The correct formatting is important, otherwise the camera will not accept the digital certificate. The camera uses certificates in PEM format. The certificate file must therefore have the file extension `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.pem`}</code>{`.`}</p>
    <p>{`Below you can see a blank sample certificate. You need your private key (Private RSA Key or Private Key) and the X.509 certificate, which contains your public key that you received from your certification authority.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`---`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`BEGIN RSA PRIVATE KEY`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`---`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`
MIIEogIBAAKCAQEAwONaLOP7EdegqjRuQKSDXzvHmFMZfBufjhELhNjo5KsL4ieH
hMSGCcSV6y32hzhqR5lvTViaQez+xhc58NZRu+OUgEhodRBW/vAOjpz/xdMz5HaC
EhP3E9W1pkitVseS8B5rrgJo1BfCGai1fPav1nutPq2Kj7vMy24+g460Lonf6ln1
di4aTIRtAqXtUU6RFpPJP35PkCXbTK65O8HJSxxt/XtfoezHCU5+UIwmZGYx46UB
Wzg3IfK6bGPSiHU3pdiTol0uMPt/GUK+x4NyZJ4/ImsNAicRwMBdja4ywHKXJehH
gXBthsVIHbL21x+4ibsg9eVM/XioTV6tW3IrdwIDAQABAoIBACFfdLutmkQFBcRN
HAJNNHmmsyr0vcUOVnXTFyYeDXV67qxrYHQlOHe6LqIpKq1Mon7O2kYMnWvooFAP
trOnsS6L+qaTYJdYg2TKjgo4ubw1hZXytyB/mdExuaMSkgMgtpia+tB5lD+V+LxN
x1DesZ+veFMO3Zluyckswt4qM5yVa04YFrt31H0E1rJfIen61lidXIKYmHHWuRxK
SadjFfbcqJ6P9ZF22BOkleg5Fm5NaxJmyQynOWaAkSZa5w1XySFfRjRfsbDr64G6
+LSG8YtRuvfxnvUNhynVPHcpE40eiPo6v8Ho6yZKXpV5klCKciodXAORsswSoGJa
N3nnu/ECgYEA6Yb2rM3QUEPIALdL8f/OzZ1GBSdiQB2WSAxzl9pR/dLF2H+0pitS
to0830mk92ppVmRVD3JGxYDRZQ56tlFXyGaCzJBMRIcsotAhBoNbjV0i9n5bLJYf
BmjU9yvWcgsTt0tr3B0FrtYyp2tCvwHqlxvFpFdUCj2oRw2uGpkhmNkCgYEA03M6
WxFhsix3y6eVCVvShfbLBSOqp8l0qiTEty+dgVQcWN4CO/5eyaZXKxlCG9KMmKxy
Yx+YgxZrDhfaZ0cxhHGPRKEAxM3IKwT2C8/wCaSiLWXZZpTifnSD99vtOt4wEfrG
+AghNd5kamFiM9tU0AyvhJc2vdJFuXrfeC7ntM8CgYBGDA+t4cZcbRhu7ow/OKYF
kulP3nJgHP/Y+LMrl3cEldZ2jEfZmCElVNQvfd2XwTl7injhOzvzPiKRF3jDez7D
g8w0JAxceddvttJRK9GoY4l7OoeKpjUELSnEQkf+yUfOsTbXPXVY7jMfeNL6jE6b
qN7t3qv8rmXtejMBE3G6cQKBgGR5W2BMiRSlxqKx1cKlrApV87BUe1HRCyuR3xuA
d6Item7Lx1oEi7vb242yKdSYnpApWQ06xTh83Y/Ly87JaIEbiM0+h+P8OEIg0F1a
iB+86AcUX1I8KseVy+Np0HbpfwP8GrFfA5DaRPK7pXMopEtby8cAJ1XZZaI1/ZvZ
BebHAoGAcQU9WvCkT+nIp9FpXfBybYUsvgkaizMIqp66/l3GYgYAq8p1VLGvN4v5
ec0dW58SJrCpqsM3NP78DtEzQf9OOsk+FsjBFzDU2RkeUreyt2/nQBj/2mN/+hEy
hYN0Zii2yTb63jGxKY6gH1R/r9dL8kXaJmcZrfSa3AgywnteJWg=
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`---`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`END RSA PRIVATE KEY`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`---`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`---`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`BEGIN CERTIFICATE`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`---`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`
MIIDBjCCAe4CCQCX05m0b053QzANBgkqhkiG9w0BAQQFADBFMQswCQYDVQQGEwJB
VTETMBEGA1UECBMKU29tZS1TdGF0ZTEhMB8GA1UEChMYSW50ZXJuZXQgV2lkZ2l0
cyBQdHkgTHRkMB4XDTA4MTIwNzEwMjUyMloXDTE4MTIwNTEwMjUyMlowRTELMAkG
A1UEBhMCQVUxEzARBgNVBAgTClNvbWUtU3RhdGUxITAfBgNVBAoTGEludGVybmV0
IFdpZGdpdHMgUHR5IEx0ZDCCASIwDQYJKoZIhvcNAQEBBQADggEPADCCAQoCggEB
AMDjWizj+xHXoKo0bkCkg187x5hTGXwbn44RC4TY6OSrC+Inh4TEhgnElest9oc4
akeZb01YmkHs/sYXOfDWUbvjlIBIaHUQVv7wDo6c/8XTM+R2ghIT9xPVtaZIrVbH
kvAea64CaNQXwhmotXz2r9Z7rT6tio+7zMtuPoOOtC6J3+pZ9XYuGkyEbQKl7VFO
kRaTyT9+T5Al20yuuTvByUscbf17X6HsxwlOflCMJmRmMeOlAVs4NyHyumxj0oh1
N6XYk6JdLjD7fxlCvseDcmSePyJrDQInEcDAXY2uMsBylyXoR4FwbYbFSB2y9tcf
uIm7IPXlTP14qE1erVtyK3cCAwEAATANBgkqhkiG9w0BAQQFAAOCAQEAW4yZdqpB
oIdiuXRosr86Sg9FiMg/cn+2OwQ0QIaA8ZBwKsc+wIIHEgXCS8J6316BGQeUvMD+
plNe0r4GWzzmlDMdobeQ5arPRB89qd9skE6pAMdLg3FyyfEjz3A0VpskolW5VBMr
P5R7uJ1FLgH12RyAjZCWYcCRqEMOffqvyMCH6oAjyDmQOA5IssRKX/HsHntSH/HW
W7slTcP45ty1b44Nq22/ubYk0CJRQgqKOIQ3cLgPomN1jNFQbAbfVTaK1DpEysrQ
5V8a8gNW+3sVZmV6d1Mj3pN2Le62wUKuV2g6BNU7iiwcoY8HI68aRxz2hVMS+t5f
SEGI4JSxV56lYg==
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`---`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`END CERTIFICATE`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`---`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span></code></pre></div>
    <h2 {...{
      "id": "private-rsa-key-or-private-key",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#private-rsa-key-or-private-key",
        "aria-label": "private rsa key or private key permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`"Private RSA Key" or "Private Key"?`}</h2>
    <p>{`You only need one! It does not matter which key you use here, as long as it is correctly identified.`}</p>
    <p>{`With `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`-----BEGIN RSA PRIVATE KEY-----`}</code>{` you set the beginning of the "Private RSA Key" and with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`-----END RSA PRIVATE KEY-----`}</code>{` you set the end.`}</p>
    <p>{`With `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`-----BEGIN PRIVATE KEY-----`}</code>{` you set the beginning of the "Private Key" and with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`-----END PRIVATE KEY-----`}</code>{` you set the end.`}</p>
    <p>{`In addition to one of the private keys mentioned above, you also need the X.509 certificate with your public key.`}</p>
    <p>{`With `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`-----BEGIN CERTIFICATE-----`}</code>{` you set the start of the X.509 certificate. With `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`-----END CERTIFICATE-----`}</code>{` you set the end.`}</p>
    <h2 {...{
      "id": "important-note",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#important-note",
        "aria-label": "important note permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Important note!`}</h2>
    <p>{`It is important that the Private RSA Key or the Private Key matches the X.509 certificate. If the HASH values do not match, the camera will discard the certificate.`}</p>
    <p>{`If you want to change the certificate, the camera must be restarted afterwards. Only then will the newly selected certificate be valid.`}</p>
    <h2 {...{
      "id": "mqtt",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#mqtt",
        "aria-label": "mqtt permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`MQTT`}</h2>
    <p>{`If you upload a private certificate here, it will also be used for the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/"
      }}>{`INSTAR MQTT Broker`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      